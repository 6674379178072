import React, { useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import {
  LayoutLp,
  SEO,
  AssetImage,
  LWrap,
  CAnchorNav,
} from '../../../components/_index';
import Slider from 'react-slick';
import { useMobile } from '../../../utils/use-series';
import '../../../assets/_sass/page/sp/doraemon.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();
  const settings = {
    dots: true,
    arrows: false,
    autoplay: false,
    speed: 1500,
  };

  // SPテキストの「続きを見る」をセクションごとに制御
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({});

  const handleShowMore = (sectionId: string) => {
    setExpandedSections({
      ...expandedSections,
      [sectionId]: !expandedSections[sectionId],
    });
  };

  // スクロール開始後にサイド固定の予約モジュールを表示
  const [showSideSearch, setShowSideSearch] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const threshold = 720;

      setShowSideSearch(scrollTop > threshold);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <LayoutLp isAfternoon={true}>
      <SEO
        title={frontmatter?.title}
        description={frontmatter?.description}
        ogimage={`${process.env.BASE_URL}/assets/images/sp/doraemon/ogimage.png`}
      />
      <main id="pageTop">
        <div className="p_doraemon">
          <section className="lp_kv">
            {!isSp ? (
              <figure className="imgWrapper">
                <AssetImage
                  src="/assets/images/sp/doraemon/kv.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
            ) : (
              <figure className="imgWrapper">
                <AssetImage
                  src="/assets/images/sp/doraemon/kv__sp.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
            )}
          </section>
          <div className="lp_kvLead">
            <div className="lp_kvLeadText">
              『映画ドラえもん のび太の地球交響楽（ちきゅうシンフォニー）』と、
              <br />
              ロイヤルパークホテルズがタイアップ！
              <br />
              各ホテル限定の客室やメニューをこの機会にぜひお楽しみください
            </div>
            <LWrap>
              <CAnchorNav
                exClass="newyear"
                data={[
                  {
                    title: '宿泊',
                    link: {
                      href: '#stay',
                    },
                  },
                  {
                    title: 'レストラン',
                    link: {
                      href: '#restaurant',
                    },
                  },
                  {
                    title: 'テイクアウト',
                    link: {
                      href: '#takeout',
                    },
                  },
                ]}
              />
            </LWrap>
          </div>
          <section className="lp_sect lp_sect--pattern01">
            <h3 className="lp_sectTitle" id="stay">
              宿泊<span>STAY</span>
            </h3>
            <div className="lp_sectContents">
              <div className="lp_sectWrapper">
                <div className="lp_sectSlider">
                  <Slider {...settings} className="slider">
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider_stay.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider_stay02.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider_stay03.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider_stay04.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider_stay05.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </Slider>
                </div>
                <div className="lp_lead lp_lead--pattern01">
                  <div className="lp_setPlanTitle">
                    ロイヤルパークホテル
                    <br />
                    （東京・日本橋）
                  </div>
                  <p className="lp_sectLead">
                    木目調の風合いを生かし、ゆったりと落ち着いた雰囲気のデラックスタイプの客室が、期間限定で映画ドラえもんに染まります！オリジナルキャラクターも加わり、更ににぎやかにしてくれるドラえもんとその仲間たちがお客様をお迎えいたします。
                  </p>
                </div>
                <p className="lp_moreButton lp_moreButton--01">
                  <AssetImage
                    src="/assets/images/sp/doraemon/button.png"
                    alt=""
                    loading="lazy"
                  />
                </p>
              </div>

              <div className="lp_sectWrapper lp_sectWrapper__reverse">
                <div className="lp_sectSlider">
                  <Slider {...settings} className="slider">
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider02_stay.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider02_stay02.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider02_stay03.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider02_stay04.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider02_stay05.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </Slider>
                </div>
                <div className="lp_lead lp_lead--pattern01">
                  <div className="lp_setPlanTitle">
                    横浜ロイヤルパーク
                    <br />
                    ホテル
                  </div>
                  <p className="lp_sectLead">
                    スカイリゾートフロア「アトリエ」が今だけ『映画ドラえもん のび太の地球交響楽（ちきゅうシンフォニー）』の世界観を再現した客室に変身します！地上245mからの絶景と横浜の空を自由に飛ぶドラえもんやのび太たちが彩りを添えます。
                  </p>
                </div>
                <p className="lp_moreButton lp_moreButton--01">
                  <AssetImage
                    src="/assets/images/sp/doraemon/button02.png"
                    alt=""
                    loading="lazy"
                  />
                </p>
              </div>

              <div className="lp_sectWrapper">
              <div className="lp_sectSlider">
                  <Slider {...settings} className="slider">
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider05_stay01.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider05_stay02.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider05_stay03.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider05_stay04.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </Slider>
                </div>
                <div className="lp_lead lp_lead--pattern01">
                  <div className="lp_setPlanTitle">
                    ザ ロイヤルパーク
                    <br />
                    キャンバス 名古屋
                  </div>
                  <p className="lp_sectLead">
                  チェアに座った大きなぬいぐるみのドラえもんが皆さまをお出迎えします。入った瞬間から映画の中に飛び込んだような世界が広がる客室で、隠された？！秘密道具（ミニスタンディ）を探しながら、「映画ドラえもん」の世界観をお楽しみください。
                  </p>
                </div>
                <p className="lp_moreButton lp_moreButton--03">
                  <Link
                    to="https://www.royalparkhotels.co.jp/canvas/nagoya/news/cc119qj44j"
                    target="_blank">
                    <AssetImage
                      src="/assets/images/sp/doraemon/button12.png"
                      alt=""
                      loading="lazy"
                    />
                  </Link>
                </p>
              </div>

              <div className="lp_sectWrapper lp_sectWrapper__reverse">
                <div className="lp_sectSlider">
                  <Slider {...settings} className="slider">
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider03_stay.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider03_stay02.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider03_stay03.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </Slider>
                </div>
                <div className="lp_lead lp_lead--pattern01">
                  <div className="lp_setPlanTitle">
                    ザ ロイヤルパークホテル
                    <br />
                    京都梅小路
                  </div>
                  <p className="lp_sectLead">
                    映画ドラえもんの世界観が部屋中にあふれるタイアップルームが期間限定で登場します！入った瞬間から壁一面に広がり、楽器を楽しそうに奏でるドラえもんたちが、皆さまを「地球交響楽」へ誘います。
                  </p>
                </div>
                <p className="lp_moreButton lp_moreButton--01">
                  <AssetImage
                    src="/assets/images/sp/doraemon/button03.png"
                    alt=""
                    loading="lazy"
                  />
                </p>
              </div>

              <div className="lp_sectWrapper u_mb0">
                <div className="lp_sectSlider">
                  <Slider {...settings} className="slider">
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider04_stay.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider04_stay02.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider04_stay03.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider04_stay04.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </Slider>
                </div>
                <div className="lp_lead lp_lead--pattern01">
                  <div className="lp_setPlanTitle">
                    ザ ロイヤルパーク
                    <br />
                    キャンバス 福岡中洲
                  </div>
                  <p className="lp_sectLead">
                    2023年8月開業後初となるタイアップ、映画ドラえもんルームが登場します。ドラえもんたちが奏でる音楽が聞こえてきそうな客室で、何気ない場所に隠れたドラえもんを探しながら、癒しのステイをお楽しみください。
                  </p>
                </div>
                <p className="lp_moreButton lp_moreButton--01">
                  <AssetImage
                    src="/assets/images/sp/doraemon/button04.png"
                    alt=""
                    loading="lazy"
                  />
                </p>
              </div>
            </div>
          </section>

          <section className="lp_sect">
            <h3 className="lp_sectTitle" id="restaurant">
              レストラン<span>RESTAURANT</span>
            </h3>
            <div className="lp_sectContents">
              <div className="lp_sectWrapper">
                <div className="lp_sectSlider">
                  <Slider {...settings} className="slider">
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider_restaurant.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </Slider>
                </div>
                <div className="lp_lead">
                  <div className="lp_contentsNumber lp_contentsNumber--restaurant">
                    ロイヤルパークホテル（東京・日本橋）
                  </div>
                  <div className="lp_setPlanTitle">映画ドラえもんプレート</div>
                  <p className="lp_sectLead">
                    甘口の「ドラえもん」カレーライスと、手を入れてみたくなりそうな「四次元ポケット」型のピタパンサンドや、そっと開けてみたいチキンクリーム入り「どこでもドア」も世代を超えて多くの方にお楽しみいただけるプレートです。
                  </p>
                </div>
                <p className="lp_moreButton lp_moreButton--01">
                  <AssetImage
                    src="/assets/images/sp/doraemon/button05.png"
                    alt=""
                    loading="lazy"
                  />
                </p>
              </div>

              <div className="lp_sectWrapper lp_sectWrapper__reverse">
                <div className="lp_sectSlider">
                  <Slider {...settings} className="slider">
                    <div>
                      <AssetImage
                        src="/assets/images/sp/doraemon/slider02_restaurant.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </Slider>
                </div>
                <div className="lp_lead">
                  <div className="lp_contentsNumber lp_contentsNumber--restaurant">
                    ロイヤルパークホテル（東京・日本橋）
                  </div>
                  <div className="lp_setPlanTitle">
                    映画ドラえもんデザートプレート
                  </div>
                  <p className="lp_sectLead">
                    赤色が特徴的なピアノ型のイチゴのムースと、ドラえもんが大好物な「どら焼き」をモチーフにしたアイスをのせたプレートは、今にもどこからともなく『映画ドラえもん』の音楽が聞こえてきそうな、魅力いっぱいのデザートです。
                  </p>
                </div>
                <p className="lp_moreButton lp_moreButton--01">
                  <AssetImage
                    src="/assets/images/sp/doraemon/button07.png"
                    alt=""
                    loading="lazy"
                  />
                </p>
              </div>
              <div className="lp_leadSub u_mb0">
                <figure className="imgWrapper">
                  <AssetImage
                    src="/assets/images/sp/doraemon/sect01_sub.png"
                    alt=""
                    loading="lazy"
                  />
                </figure>
                <div className="lp_sectLead">
                  <span className="lp_sectLead_sub">さらにドリンクも！</span>
                  <div className="lp_sectLead_title">
                    ドラえもんのクリームソーダ
                    <br />
                    ミッカのフルーツスカッシュ
                  </div>
                  <p className="lp_sectLead_subText">
                    ドラえもんカラーのさっぱりとした味わいが特徴のソフトドリンクや、ピーチやレモン、キウイの味わいが楽しい、ミッカをイメージした爽やかなフルーツのソフトドリンクもどうぞ。
                    <br />
                    <span>
                      ※シェフズダイニング シンフォニー、
                      <br />
                      ロビーラウンジ フォンテーヌにて提供
                    </span>
                  </p>
                  <span className="lp_moreButton lp_moreButton--02">
                    <AssetImage
                      src="/assets/images/sp/doraemon/button08.png"
                      alt=""
                      loading="lazy"
                    />
                  </span>
                </div>
              </div>
            </div>
          </section>

          <section className="lp_sect lp_sect--pattern01">
            <h3 className="lp_sectTitle" id="takeout">
              テイクアウト<span>TAKE OUT</span>
            </h3>
            <div className="lp_sectContents">
              <div className="lp_leadSub lp_leadSub--takeout">
                <figure className="imgWrapper">
                  <AssetImage
                    src="/assets/images/sp/doraemon/img_takeout.png"
                    alt=""
                    loading="lazy"
                  />
                </figure>
                <div className="lp_sectLead">
                  <span className="lp_contentsNumber lp_contentsNumber--takeout">
                    ロイヤルパークホテル（東京・日本橋）
                  </span>
                  <div className="lp_sectLead_title">
                    スイーツ&ベーカリー 粋
                  </div>
                  <p>
                    食べるのがもったいないくらい可愛らしい『ドラえもんムースケーキ』も、ふわふわもちもち食感の『ドラえもん生食パン』も、
                    <br />
                    コンプリートしたくなる『ドラえもんクロワッサン3種「ドラえもん」「のび太」「しずか」』もこの機会だけ！
                  </p>
                  <span className="lp_moreButton lp_moreButton--02">
                    <AssetImage
                      src="/assets/images/sp/doraemon/button09.png"
                      alt=""
                      loading="lazy"
                    />
                  </span>
                </div>
              </div>

              <div className="lp_leadSub lp_leadSub--takeout u_mb0">
                <figure className="imgWrapper">
                  <AssetImage
                    src="/assets/images/sp/doraemon/img_takeout02.png"
                    alt=""
                    loading="lazy"
                  />
                </figure>
                <div className="lp_sectLead">
                  <span className="lp_contentsNumber lp_contentsNumber--takeout">
                    横浜ロイヤルパークホテル
                  </span>
                  <div className="lp_sectLead_title">
                    デリカ＆ラウンジ コフレ
                  </div>
                  <p>
                    思わず3種類集めたくなる可愛さのホテルオリジナルプリンは燕尾服姿で指揮をするドラえもんが描かれた容器でご提供。ドラえもんの焼印が入ったイングリッシュマフィンも「ツナコーン」と「カスタード」の2種類をご用意しました。 
                  </p>
                  <span className="lp_moreButton lp_moreButton--02">
                    <AssetImage
                      src="/assets/images/sp/doraemon/button10.png"
                      alt=""
                      loading="lazy"
                    />
                  </span>
                </div>
              </div>
            </div>
          </section>

          <section className="lp_sect">
            <h3 className="lp_sectTitle u_mb15">
              スタンプラリー<span>STAMP RALLY</span>
            </h3>
            <p className="u_mb30 u_tac">※～4/26終了。</p>
            <div className="lp_sectContents">
              <div className="lp_leadStamp">
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section1'] ? 'expanded' : ''
                  }`}
                >
                  「ロイヤルパークホテル（東京・日本橋）」「横浜ロイヤルパークホテル」「ザ
                  ロイヤルパークホテル 京都梅小路」の3ホテルでは、
                  <br />
                  宿泊されていないお客様でも無料で楽しめるARスタンプラリーを開催。
                  <br />
                  ARサービスアプリ「COCOAR（ココアル）」を使って読み取り、映画ドラえもんのキャラクターたちをみつけてください。
                  <br />
                  ARのキャラクター全てを見つけ出してスタンプラリーを完了された方には、
                  <br />
                  ホテルズオリジナルステッカーをプレゼントします（各ホテル数量限定につき、無くなり次第終了となります）。
                  <br />
                  ドラえもんルームに宿泊されるお客様は客室内の限定キャラクターも探してみてください！
                </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section1')}
                  >
                    {expandedSections['section1'] ? '閉じる' : '続きを見る'}
                  </span>
                )}
              </div>
              {!isSp ? (
                <figure className="imgWrapper imgWrapperStamp">
                  <AssetImage
                    src="/assets/images/sp/doraemon/img_illust.png"
                    alt=""
                    loading="lazy"
                  />
                </figure>
              ) : (
                <figure className="imgWrapper imgWrapperStamp">
                  <AssetImage
                    src="/assets/images/sp/doraemon/img_illust__sp.png"
                    alt=""
                    loading="lazy"
                  />
                </figure>
              )}
            </div>
          </section>

          <section className="lp_sect lp_sect--pattern02">
            <div className="lp_sectBanner">
              <Link to="https://doraeiga.com/2024/" target="_blank">
                {!isSp ? (
                  <figure className="imgWrapper">
                    <AssetImage
                      src="/assets/images/sp/doraemon/img_banner.png"
                      alt=""
                      loading="lazy"
                    />
                  </figure>
                ) : (
                  <figure className="imgWrapper">
                    <AssetImage
                      src="/assets/images/sp/doraemon/img_banner__sp.png"
                      alt=""
                      loading="lazy"
                    />
                  </figure>
                )}
              </Link>
            </div>
          </section>

          <section>
            <div className="instagram">
              <LWrap>
                <Link
                  to="https://www.instagram.com/royalparkhotels__official/"
                  target="_blank"
                >
                  <AssetImage
                    src="/assets/images/sp/doraemon/instagram.png"
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </LWrap>
            </div>
          </section>
          {showSideSearch && (
            <a href="#pageTop">
              <figure className="imgWrapper imgWrapper--top">
                <AssetImage
                  src="/assets/images/sp/doraemon/top_button.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
            </a>
          )}
        </div>
      </main>
    </LayoutLp>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
